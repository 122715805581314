import { Directive, ElementRef, NgZone, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

@Directive({
  selector: '[HideWhenKeyBoardShowsUp]',
  standalone: true
})
export class HideWhenKeyBoardShowsUpDirective implements OnInit {
  private isKeyboardDisplayed = false;

  constructor(private elementRef: ElementRef, private ngZone: NgZone, private platform: Platform) {}

  ngOnInit(): void {
    if (typeof window !== 'undefined' && (this.platform.is('android') || this.platform.is('ios'))) {
      window.addEventListener('ionKeyboardDidShow', this.onKeyboardShow.bind(this));
      window.addEventListener('ionKeyboardDidHide', this.onKeyboardHide.bind(this));
    }
  }

  private onKeyboardShow(ev: any): void {
    this.ngZone.run(() => {
      this.isKeyboardDisplayed = true;
      this.applyDisplayStyle();
    });
  }

  private onKeyboardHide(): void {
    this.ngZone.run(() => {
      this.isKeyboardDisplayed = false;
      this.applyDisplayStyle();
    });
  }

  private applyDisplayStyle(): void {
    if (this.isKeyboardDisplayed) {
      this.elementRef.nativeElement.style.display = 'none';
    } else {
      this.elementRef.nativeElement.style.display = '';
    }
  }
}
