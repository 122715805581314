import { ElementRef, Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";

@Injectable()
export class DirectiveService {

  constructor(
  ) {
  }

  handlerApplyMask(el: ElementRef, applyMask: Function, keySelected: any, pipe = null) {

    const initialValue: string = pipe ? applyMask(el.nativeElement.value, pipe) : el.nativeElement.value;
    const itemInput = this.getElementInput(el);

    const positionStartCursor = itemInput.selectionStart ?? 0;
    const positionEndCursor = itemInput.selectionEnd;
    const newValue: string = pipe ? applyMask(initialValue, pipe) : applyMask(initialValue);
    el.nativeElement.value = newValue;

    if (initialValue.length != positionStartCursor || initialValue.length != positionEndCursor) {
      // o usuario colocou o cursor em outra posição no input

      // a mascara adicionou algum caracter novo.
      const regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

      // console.log(initialValue[positionStartCursor]);
      // console.log(newValue[positionStartCursor]);

      // se o proximo caracter do curso é especial
      if (regex.test(newValue[positionStartCursor - 1])) {

        if (keySelected.backspacePressed) {
          keySelected.backspacePressed = false;
          // itemInput.setSelectionRange(positionStartCursor - 1, positionEndCursor - 1);
        }
        else if (keySelected.deletePressed) {
          keySelected.deletePressed = false;
          // itemInput.setSelectionRange(positionStartCursor, positionEndCursor);
        }
        else {
          // itemInput.setSelectionRange(positionStartCursor + 1, positionEndCursor + 1);
        }
      }
      else {
        if (regex.test(newValue[positionStartCursor]) && (keySelected.deletePressed || keySelected.backspacePressed)) {
          if (keySelected.deletePressed) {
            keySelected.deletePressed = false;
            const newValueMIni = newValue.substr(0, positionStartCursor);
            const newValueMFin = newValue.substr(positionStartCursor + 2);
            el.nativeElement.value = pipe ? applyMask(newValueMIni + newValueMFin, pipe) : applyMask(newValueMIni + newValueMFin);
            // itemInput.setSelectionRange(positionStartCursor, positionEndCursor);
          } else {
            keySelected.backspacePressed = false;
            const newValueMIni = newValue.substr(0, positionStartCursor - 1);
            const newValueMFin = newValue.substr(positionStartCursor + 1);
            el.nativeElement.value = pipe ? applyMask(newValueMIni + newValueMFin, pipe) : applyMask(newValueMIni + newValueMFin);
            // itemInput.setSelectionRange(positionStartCursor, positionEndCursor);
          }
        } else {
          // itemInput.setSelectionRange(positionStartCursor, positionEndCursor);
        }
      }
    }

    return el;
  }

  handlerOnKeyDown(event: any) {
    const keySelected = {
      backspacePressed: false,
      deletePressed: false,
    }

    if (event.key === 'Backspace') {
      keySelected.backspacePressed = true;
      keySelected.deletePressed = false;
    } else if (event.key === 'Delete') {
      keySelected.deletePressed = true;
      keySelected.backspacePressed = false;
    } else {
      keySelected.backspacePressed = false;
      keySelected.deletePressed = false;
    }

    return keySelected;
  }

  private getElementInput(el: ElementRef) {
    return el.nativeElement as HTMLInputElement;
  }

  /**
   * Usar para ion-input. Caso use input do HTML, chame o método getElementInput().
   */
  private getElementInputFromIonInput(el: ElementRef) {
    const input = el.nativeElement as HTMLInputElement;
    const itemInput = (input.children[0] as any);
    return itemInput;
  }

  validarCPF(control: FormControl): { [key: string]: any } | null {

    if (!control.value) return { cpfInvalido: true };

    const cpf = control?.value?.replace(/\D/g, ''); // Remove caracteres não numéricos

    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
      return { cpfInvalido: true };
    }

    let soma = 0;
    let resto;

    for (let i = 1; i <= 9; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) {
      resto = 0;
    }

    if (resto !== parseInt(cpf.substring(9, 10))) {
      return { cpfInvalido: true };
    }

    soma = 0;

    for (let i = 1; i <= 10; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) {
      resto = 0;
    }

    if (resto !== parseInt(cpf.substring(10, 11))) {
      return { cpfInvalido: true };
    }

    return null;
  }

  validarDataNascimento(control: FormControl): { [key: string]: any } | null {
    const dataNascimento = control.value;

    const isValidDate = (dateString: string): boolean => {
      const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

      if (!dateRegex.test(dateString)) {
        return false;
      }

      const [day, month, year] = dateString.split('/');
      const date = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));

      return (
        date.getDate() === parseInt(day, 10) &&
        date.getMonth() === parseInt(month, 10) - 1 &&
        date.getFullYear() === parseInt(year, 10)
      );
    }

    if (!isValidDate(dataNascimento)) {
      return { dataInvalida: true };
    }

    const dataAtual = new Date();
    const [day, month, year] = dataNascimento.split('/');

    const dataFornecida = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));

    // Verificar se a data de nascimento é futura ao dia atual
    if (dataFornecida > dataAtual) {
      return { dataFutura: true };
    }

    // Verificar se a data de nascimento é mais antiga que 120 anos atrás
    const limiteMinimo = new Date(dataAtual.getFullYear() - 120, dataAtual.getMonth(), dataAtual.getDate());
    if (dataFornecida < limiteMinimo) {
      return { dataPassada: true };
    }

    return null;
  }
}
