import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';
import { Preferences } from '@capacitor/preferences';
import { GoogleAuth, User as GoogleUser } from '@codetrix-studio/capacitor-google-auth';
import { Observable, delay, filter, from, of, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ISinafLoginErrorResponse, ISinafLoginSuccessResponse } from '../interfaces/sinaf-login-response.interface';
import { HttpService } from './http.service';
import { AlertService } from './alert.service';
import { AppComponent } from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user: ISinafLoginSuccessResponse | null | any;

  constructor(
    private httpService: HttpService,
    private router: Router,
    private alertService: AlertService
  ) {
    this.loadUser();
  }

  ngOnInit() {
    this.loadUser();
  }

  isUserLogged(): boolean {
    return !!this.user;
  }

  async loadUser() {
    this.user = await this.getUser();
  }

  async isUserAuthenticated() {
    this.user = await this.getUser();
    return this.user !== null;
  }

  doLoginWithPassword(cpf: string, password: string) {
    return new Observable(observer => {
      this.sinafLogin(cpf, password).subscribe({
        next: async (response: ISinafLoginSuccessResponse | ISinafLoginErrorResponse) => {
          if ("access_token" in response) {
            await this.setUserSession(response, 'password');
          } else {
            console.error('ocorreu um erro no sinaf login', response);
          }
          this.setLoginType('password');
          observer.next(true);
          observer.complete();
        },
        error: (error: HttpErrorResponse) => {
          observer.error(error);
          console.error(error);
        },
        complete: () => { }
      });
    });
  }

  sinafLogin(username: string, password: string): Observable<ISinafLoginSuccessResponse | ISinafLoginErrorResponse> {
    return this.httpService.post(environment.API_URL + "/user/login", { username: username, password: password });
  }

  googleSignIn() {
    return new Observable(observer => {
      this.googleSignOut();
      GoogleAuth.signIn().then((response: GoogleUser) => {
        this.sinafSocialSignIn(response.authentication.idToken, "GOOGLE").subscribe({
          next: async (response: ISinafLoginSuccessResponse | ISinafLoginErrorResponse) => {
            if ("access_token" in response) {
              await this.setUserSession(response, 'google');
            } else {
              console.error('ocorreu um erro no sinaf social login', response);
            }
            this.setLoginType('google');
            observer.next(true);
            observer.complete();
          },
          error: (error: HttpErrorResponse) => {
            observer.error(error.error ?? error.message ?? error);
            console.error(error);
          },
          complete: () => { }
        });
      }).catch(error => {
        console.log(error);
      });
    });
  }

  googleLogin() {
    return new Observable(observer => {
      this.googleSignOut();
      GoogleAuth.signIn().then((response: GoogleUser) => {
        this.sinafSocialLogin(response.authentication.idToken, "GOOGLE").subscribe({
          next: async (response: ISinafLoginSuccessResponse | ISinafLoginErrorResponse) => {
            if ("access_token" in response) {
              await this.setUserSession(response, 'google');
            } else {
              console.error('ocorreu um erro no sinaf social login', response);
              observer.error('Erro no social login');
            }
            observer.next(true);
            observer.complete();
          },
          error: (error: HttpErrorResponse) => {
            if (error?.status == 401 && error?.error?.message) {
              this.alertService.showToastError(error.error.message);
              this.alertService.dismissLoaders();
              observer.error(null);
            } else {
              observer.error(error.error ?? error.message ?? error);
            }
            console.error(error);
          },
          complete: () => { }
        });
      }).catch(error => {
        console.log(error);
        observer.error(error);
      });
    });
  }

  googleSignOut() {
    GoogleAuth.signOut().then(() => {
      this.user = null;
      console.log('Google Signout done');
    }).catch(error => {
      console.error('Erro deslogando com Google Auth: ', error);
    });
  }

  facebookSignIn() {
    return new Observable(observer => {
      FacebookLogin.login({ permissions: environment.Facebook.permissions }).then((result: FacebookLoginResponse) => {
        if (result.accessToken) {
          this.sinafSocialSignIn(result.accessToken.token, "FACEBOOK").subscribe({
            next: async (response: ISinafLoginSuccessResponse | ISinafLoginErrorResponse) => {
              if ("access_token" in response) {
                await this.setUserSession(response, 'facebook');
              } else {
                observer.error('Ocorreu um erro no sigin social Facebook');
              }
            },
            error: (error: HttpErrorResponse) => {
              if (error?.status == 401 && error?.error?.message) {
                this.alertService.showToastError(error.error.message);
                this.alertService.dismissLoaders();
                observer.error(null);
              } else {
                observer.error(error.error ?? error.message ?? error);
              }
            },
            complete: () => {
              observer.next(true);
              observer.complete();
            }
          });
        } else {
          observer.error('Ocorreu algum erro no Login com o Facebook');
        }
      }).catch(error => {
        observer.error(error);
        console.error('Erro login Facebook: ', error);
      });
    });
  }

  facebookLogin() {
    return new Observable(observer => {
      FacebookLogin.login({ permissions: environment.Facebook.permissions }).then((result: FacebookLoginResponse) => {
        if (result?.accessToken && result?.accessToken?.token) {
          this.sinafSocialLogin(result.accessToken.token, "FACEBOOK").subscribe(
            async (response: ISinafLoginSuccessResponse | ISinafLoginErrorResponse) => {
              if ("access_token" in response) {
                await this.setUserSession(response, 'facebook');
              } else {
                observer.error('Ocorreu um erro no login social Facebook');
                console.error(response);
              }
            },
            (error: HttpErrorResponse) => {
              observer.error(error.error ?? error.message ?? error);
            },
            () => {
              observer.next(true);
              observer.complete();
            }
          );
        } else {
          observer.error('Ocorreu algum erro no login com o Facebook');
        }
      }).catch(error => {
        observer.error(error);
        console.error('Erro login Facebook: ', error);
      });
    });
  }

  facebookLogout() {
    FacebookLogin.logout().then(() => {
      this.user = null;
      console.log('Facebook Signout done');
    }).catch(error => {
      console.error('Erro deslogando com Facebook:', error);
    });
  }

  sinafSocialLogin(code: string, provider: string): Observable<ISinafLoginSuccessResponse | ISinafLoginErrorResponse> {
    const data = {
      code: code,
      provider: provider,
      terms: true
    };

    if (environment.mockAPI) {
      return this.sinafSocialLogin_SIMULATED(data);
    }

    return this.httpService.post(environment.API_URL + "/subscriber/socialLogIn", data);
  }

  sinafSocialSignIn(code: string, provider: string): Observable<ISinafLoginSuccessResponse | ISinafLoginErrorResponse> {
    const data = {
      code: code,
      provider: provider,
      terms: true
    };

    if (environment.mockAPI) {
      return this.sinafSocialLogin_SIMULATED(data);
    }

    return this.httpService.post(environment.API_URL + "/subscriber/social-sign-in", data,);
  }

  sinafSocialLogin_SIMULATED(data: any) {
    if (this.shouldThrowError()) {
      const errorList = [
        {
          error: 'Erro na chamada de API',
          message: 'O usuário já completou o primeiro acesso',
          statusCode: 400
        },
        {
          error: 'Erro na chamada de API',
          message: 'Usuário precisa aceitar os termos para prosseguir',
          statusCode: 400
        },
        {
          error: 'Erro na chamada de API',
          message: 'Esse perfil já está vinculado a outra conta',
          statusCode: 400
        },
        {
          error: 'Erro na chamada de API',
          message: 'Provedor social não suportado, provedores cadastrados: FACEBOOK, GOOGLE',
          statusCode: 400
        },
        {
          error: 'Unauthorized',
          message: 'Token inválido',
          statusCode: 401
        }
      ];

      return throwError({ error: errorList[this.getRandomItem(errorList)] });
    } else {
      const futureDate = new Date();
      futureDate.setMinutes(futureDate.getMinutes() + Math.floor(Math.random() * 3) + 1);

      const mockResponse = {
        "access_token": "string",
        "expires_in": 0,
        "refresh_expires_in": 0,
        "refresh_token": "string",
        "token_type": "string"
      };
      return of(mockResponse).pipe(delay(1000));
    }
  }

  getRandomItem(array: Array<Object>) {
    return Math.floor(Math.random() * array.length);
  }

  setUserSession(user: any, type: string | null = null): Promise<any> {
    this.user = user;

    let promises = [
      this.setUser(user),
      this.setAccessToken(user.access_token),
      this.setRefreshToken(user.refresh_token)
    ];

    if (type !== null) promises.push(this.setLoginType(type));

    return Promise.all(promises);
  }

  clearSession(): Promise<[void, void, void]> {
    return Promise.all([
      this.clearUser(),
      this.clearAccessToken(),
      this.clearRefreshToken()
    ]);
  }

  refreshToken(): Observable<ISinafLoginSuccessResponse | ISinafLoginErrorResponse> {
    return from(this.getRefreshToken()).pipe(
      filter(accessToken => accessToken !== null), // Verifica se o accessToken não é nulo
      switchMap((accessToken) => {
        return this.httpService.post(environment.API_URL + "/subscriber/refreshToken", { refreshToken: accessToken });
      })
    );
  }

  requestPasswordRecovery(document: string, birthDate: string, firstAccess: boolean = false) {

    if (environment.mockAPI) {
      return this.requestPasswordRecovery_SIMULATED(document, birthDate, firstAccess);
    }

    const data = {
      document: document,
      birthDate: birthDate,
      firstAccess: firstAccess
    };

    return this.httpService.post(environment.API_URL + "/subscriber/requestPasswordRecovery", data);
  }

  private requestPasswordRecovery_SIMULATED(document: string, birthDate: string, firstAccess: boolean = false): Observable<any> {
    const shouldThrowError = this.shouldThrowError();

    if (shouldThrowError) {
      return throwError({
        error: {
          error: 'Erro na chamada de API',
          message: 'Primeiro acesso já realizado, faça o login ou recupere sua senha para entrar',
          statusCode: 400
        }
      });
    } else {
      const mockResponse = { "id": 8581, "firstName": "AD****", "lastName": "DA SILVA MARQUES SERRA", "keycloakId": "35198f35-87ad-493e-9cd7-a211a2418942", "document": "64913612093", "birthDate": "1969-06-08T00:00:00.000Z", "status": "READY_FOR_FIRST_ACCESS", "isOwner": true, "ownerId": null, "ownerName": null, "requirePasswordUpdate": null, "height": null, "weight": null, "bloodType": null, "loginType": null, "socialId": null, "createdAt": "2023-03-15T18:30:29.753Z", "updatedAt": "2023-03-15T18:30:29.871Z", "fullName": "ADEMAR DA SILVA MARQUES SERRA", "firstLogin": null, "lastLogin": null, "subscriptions": [{ "id": 8580, "price": "0", "startAt": "2023-03-15T18:30:29.766Z", "endAt": "2024-03-14T18:30:29.766Z", "renewSubscription": true, "status": true, "planId": 1, "subscriberId": 8581, "parentSubscriptionId": null, "createdAt": "2023-03-15T18:30:29.768Z", "updatedAt": "2023-03-15T18:30:29.768Z" }], "phoneNumber": "(11) **** 7729" };
      return of(mockResponse).pipe(delay(1000));
    }
  }

  redefinePassword(password: string, access_token: string) {
    if (environment.mockAPI) {
      return this.redefinePassword_SIMULATED(password);
    }

    const data = {
      password: password,
      terms: true
    }
    return this.httpService.post(environment.API_URL + "/subscriber/redefinePassword", data, { 'Authorization': `Bearer ${access_token}` });
  }

  redefinePassword_SIMULATED(password: string) {
    const shouldThrowError = this.shouldThrowError();

    if (shouldThrowError) {
      return throwError({
        error: {
          error: 'Erro na chamada de API',
          message: 'Esse usuário não está habilitado a redefinir a senha pois está vinculado a um perfil social',
          statusCode: 400
        }
      });
    } else {
      const mockResponse = {
        "access_token": "string",
        "expires_in": 0,
        "refresh_expires_in": 0,
        "refresh_token": "string",
        "token_type": "string"
      }
      return of(mockResponse).pipe(delay(1000));
    }
  }

  sendRecoveryToken(id: number) {
    if (environment.mockAPI) {
      return this.sendRecoveryToken_SIMULATED(id);
    }
    return this.httpService.post(environment.API_URL + "/subscriber/sendRecoveryToken", { id: id });
  }

  verifyToken(phoneNumber: string, token: string, subscriberId: number) {
    const data = {
      phoneNumber: phoneNumber,
      token: token,
      subscriberId: subscriberId
    }

    if (environment.mockAPI) {
      return this.verifyToken_SIMULATED();
    }

    return this.httpService.post(environment.API_URL + "/subscriber/verifyToken", data);
  }

  validateTokenDependent(token: string, document: string, birthDate: number) {
    const data = {
      token: token,
      document: document,
      birthDate: birthDate,
      terms: true
    }

    if (environment.mockAPI) {
      return this.verifyToken_SIMULATED();
    }

    return this.httpService.post(environment.API_URL + "/subscriber/validateToken/dependent", data);
  }

  private verifyToken_SIMULATED() {
    const shouldThrowError = this.shouldThrowError();

    if (shouldThrowError) {
      const errorList = [
        {
          error: 'Bad Request',
          message: 'Você atingiu o número máximo de tentativas sem sucesso, tente novamente em 10 minutos',
          statusCode: 400
        },
        {
          error: 'Bad Request',
          message: 'Token inválido',
          statusCode: 400
        },
        {
          error: 'Bad Request',
          message: 'Esse número de telefone não pertence ao usuário informado',
          statusCode: 400
        },
        {
          error: 'Bad Request',
          message: 'Verificação expirada',
          statusCode: 400
        }
      ];

      return throwError({ error: errorList[this.getRandomItem(errorList)] });
    } else {
      const mockResponse = {
        "accessToken": "string"
      };
      return of(mockResponse).pipe(delay(1000));
    }
  }

  private sendRecoveryToken_SIMULATED(id: number) {
    const shouldThrowError = this.shouldThrowError();

    if (shouldThrowError) {
      const errorList = [
        {
          error: 'Erro na chamada de API',
          message: 'Você atingiu o número máximo de 5 tokens enviados, tente novamente em 10 minutos',
          statusCode: 400
        },
        {
          error: 'Erro na chamada de API',
          message: 'Usuário não tem um telefone para recuperação cadastrado',
          statusCode: 400
        },
        {
          error: 'Erro na chamada de API',
          message: 'Usuário não encontrado',
          statusCode: 404
        }
      ];

      return throwError({ error: errorList[this.getRandomItem(errorList)] });
    } else {
      const futureDate = new Date();
      futureDate.setMinutes(futureDate.getMinutes() + Math.floor(Math.random() * 3) + 1);

      const mockResponse = {
        "sid": "string",
        "phoneNumber": "string",
        "nextResend": futureDate.toISOString()
      };

      // const mockResponse = {"phoneNumber":"+5531988071727","sid":"VEcb03d57e11c9bc6cc610693262caff68"};

      return of(mockResponse).pipe(delay(1000));
    }
  }

  private shouldThrowError() {
    return Math.random() > 0.9; // 10% de chance de retornar um erro
  }

  getLoginType(): Promise<string> {
    return Preferences.get({ key: 'loginType' }).then((type: any) => type);
  }

  setLoginType(type: string): Promise<void> {
    return Preferences.set({ key: 'loginType', value: type });
  }

  getUser(): Promise<ISinafLoginSuccessResponse> {
    return Preferences.get({ key: 'sinafUser' }).then((user: any) => JSON.parse(user.value));
  }

  setUser(user: ISinafLoginSuccessResponse): Promise<void> {
    return Preferences.set({ key: 'sinafUser', value: JSON.stringify(user) });
  }

  clearUser(): Promise<void> {
    this.user = null;
    return Preferences.remove({ key: 'sinafUser' });
  }

  getAccessToken(): Promise<string> {
    return Preferences.get({ key: 'sinafAccessToken' }).then((token: any) => token.value);
  }

  setAccessToken(token: string): Promise<void> {
    return Preferences.set({ key: 'sinafAccessToken', value: token });
  }

  clearAccessToken(): Promise<void> {
    return Preferences.remove({ key: 'sinafAccessToken' });
  }

  getRefreshToken(): Promise<string> {
    return Preferences.get({ key: 'sinafRefreshToken' }).then((token: any) => token.value);
  }

  setRefreshToken(token: string): Promise<void> {
    return Preferences.set({ key: 'sinafRefreshToken', value: token });
  }

  clearRefreshToken(): Promise<void> {
    return Preferences.remove({ key: 'sinafRefreshToken' });
  }

  logout() {
    this.clearSession().then(() => {
      this.alertService.dismissLoaders();
      localStorage.clear();
      AppComponent.PendenciaEmmiter.next(0);
      this.getLoginType().then(loginType => {
        if (loginType == 'google') {
          this.googleSignOut();
        } else if (loginType == 'facebook') {
          this.facebookLogout();
        }
        this.router.navigate(['login']);
      }).catch(err => {
        console.error(err);
      })
    });
  }

  getUserData() {
    return this.httpService.get(environment.API_URL + '/subscriber/data')
  }

  updatePassword(credentials: object = {}) {
    return this.httpService.post(environment.API_URL + '/subscriber/auth/redefinePassword', credentials)
  }
}
