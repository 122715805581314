import { Directive, ElementRef, HostListener } from '@angular/core';
import { DirectiveService } from '../services/directive.services';

@Directive({
  selector: '[cpfMask]',
  standalone: true
})
export class CPFMaskDirective {

  keySelected: null | { backspacePressed: boolean;  deletePressed: boolean} = null;

  constructor(
    private el: ElementRef,
    private directiveSrv: DirectiveService
  ) { }

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    this.el = this.directiveSrv.handlerApplyMask(this.el, this.applyMask, this.keySelected);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: Event) {
    this.keySelected = this.directiveSrv.handlerOnKeyDown(event);
  }

  applyMask(value: string): string {
    return value.replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1-$2')
      .slice(0, 14);
  }
}
