import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyBrl',
  standalone: true
})
export class CurrencyBrlPipe implements PipeTransform {
  transform(value: number | string): string {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    if (isNaN(value)) {
      return '';
    }

    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    const parts = formatter.formatToParts(value);
    let result = '';

    for (const part of parts) {
      if (part.type === 'currency') {
        result += 'R$ ';
      } else if (part.type === 'fraction') {
        const cents = part.value.padEnd(2, '0');
        result += cents;
      } else {
        result += part.value;
      }
    }

    return result;
  }
}
