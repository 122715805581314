import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { FormsModule } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';


@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
  ],
})
export class DialogComponent {
  constructor(
    public dialogService: DialogService
  ) {}

  @Output() dialogClose: EventEmitter<void> = new EventEmitter()
  isHiding: boolean = false

  hide() {
    this.isHiding = true
    setTimeout(() => {
      this.isHiding = false
      this.dialogService.setShowModal = false
      this.dialogClose.emit()
    }, 300)
  }
}
