import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, from, map, switchMap } from 'rxjs';
import { InvitationStatus } from '../shared/invitationStatus.enum';

@Injectable({
  providedIn: 'root'
})
export class DependentesService {
  private token:string = '';

  constructor(
    private httpService: HttpService,
    private authService: AuthService,
    private router: Router
  ) {
  }

  getAll() {
    return from(this.authService.getUser()).pipe(
      switchMap(user => this.httpService.get(environment.API_URL + '/subscriber/dependents')),
    );
  }

  contaQtdPendencias(): Observable<number> {
    return this.getAll()
      .pipe(
        map((response: any) => {
          let count = 0;
          for (const obj of response) {
            if (obj.dependent && obj.dependent.status !== InvitationStatus.REGISTRATION_COMPLETED) {
              count++;
            }
          }
          return count;
        })
      );
  }

  async patchDependent(data:any) {
    let user = await this.authService.getUser();
    return this.httpService.patch(environment.API_URL + '/subscriber/dependents', data)
  }

  async sendInvite(id:{ dependentId: number, telephone: string }) {
    let user = await this.authService.getUser();
    return this.httpService.post(environment.API_URL + `/subscriber/invite/dependent?id=${id.dependentId}`, {})
  }
}
