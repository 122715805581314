import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CPFMaskDirective } from './directives/cpf-mask.directive';
import { DirectiveService } from './services/directive.services';
import { DateMaskDirective } from './directives/date-mask.directive';
import { HideWhenKeyBoardShowsUpDirective } from './directives/hide-when-keyboard-shows-up.directive';
import { CurrencyBrlPipe } from './shared/currency-brl.pipe';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CPFMaskDirective,
    DateMaskDirective,
    HideWhenKeyBoardShowsUpDirective,
    CurrencyBrlPipe
  ],
  exports: [CPFMaskDirective, DateMaskDirective, HideWhenKeyBoardShowsUpDirective, CurrencyBrlPipe],
  providers: [DirectiveService]
})
export class SharedModule { }
