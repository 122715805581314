<div *ngIf="dialogService.getShowModal">
  <div class="dialog-overlay" [ngClass]="isHiding ? 'is-hiding':''" (click)="hide()"></div>

  <div class="dialog" [ngClass]="isHiding ? 'is-hiding':''">
    <ion-card>
      <ion-card-header>
        <span *ngIf="dialogService.getDialogInfo.title">
          {{ dialogService.getDialogInfo.title }}
        </span>
      </ion-card-header>

      <ion-card-content>
        <ng-content>

        </ng-content>
      </ion-card-content>

      <div class="actions">
        <ng-content select="[actions]"></ng-content>
      </div>
    </ion-card>
  </div>
</div>
