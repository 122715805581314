import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { ERROR_MESSAGES } from '../shared/error-messages';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertController: AlertController,
    private toastController: ToastController,
    private loadingCtrl: LoadingController
  ) { }

  private whilePromise = (
    condition: () => Promise<boolean>,
    action: () => Promise<boolean>
  ) => {
    condition().then(value => {
      if (value) {
        action().then(closed => {
          if (closed) {
            this.whilePromise(condition, action);
          } else {
            // throw new Error("Could not dismiss the topmost loader. Aborting...");
          }
        });
      }
    });
  };

  async showSuccess(message: string) {
    this.dismissLoaders();

    const alert = await this.alertController.create({
      header: 'Sucesso!',
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

  async showAlertError(message: string, header: string = 'Atenção') {
    this.dismissLoaders();

    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

  showError(message: string) {
    this.dismissLoaders();
    this.showToastError(message);
  }

  async showStandardError(error: any = null) {
    if(error) console.error(error);
    return this.showToastError(this.getErrorMessage("ERR001"));
  }

  async showAlert(message: string) {
    this.dismissLoaders();

    const alert = await this.alertController.create({
      header: 'Aviso',
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

  async showConfirmation(message: string): Promise<boolean> {
    this.dismissLoaders();

    const alert = await this.alertController.create({
      header: 'Confirmação',
      message: message,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Confirmar',
          role: 'confirm',
          handler: () => {
            return true;
          }
        }
      ]
    });

    await alert.present();

    return await alert.onDidDismiss().then(data => {
      return data.role === 'confirm';
    });
  }

  async showSuccessToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      color: 'success',
      duration: 3000,
      position: 'top'
    });
    toast.present();
  }

  async showToastError(message: string) {
    const toast = await this.toastController.create({
      message: message,
      color: 'danger',
      duration: 3000,
      position: 'top'
    });
    toast.present();
  }

  async showWarningToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: 'top',
      color: 'warning',
      keyboardClose: true,
    });

    await toast.present();
  }

  showLoading(message: string = 'Carregando') {
    const loading = this.loadingCtrl.create({
      message: message,
      duration: 45000,
    });

    loading.then(loading => {
      loading.present();
    });

    return loading;
  }

  public getErrorMessage(code: string): string {
    const errorMessage = ERROR_MESSAGES.find(msg => msg.code === code);
    return errorMessage ? errorMessage.message : 'Erro desconhecido.';
  }

  dismissLoaders() {
    this.whilePromise(() => this.loadingCtrl.getTop().then(topLoader => topLoader != null), () => this.loadingCtrl.dismiss());
  }

}
