import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'home',
    loadComponent: () => import('./pages/home/home.page').then((m) => m.HomePage),
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'minha-conta',
    loadComponent: () => import('./pages/minha-conta/minha-conta.page').then( m => m.MinhaContaPage)
  },
  {
    path: 'carteirinha',
    loadComponent: () => import('./pages/carteirinha/carteirinha.page').then( m => m.CarteirinhaPage)
  },
  {
    path: 'ajuda',
    loadComponent: () => import('./pages/ajuda/ajuda.page').then( m => m.AjudaPage)
  },
  {
    path: 'ajuda/topico-ajuda',
    loadComponent: () => import('./pages/ajuda/topico-ajuda/topico-ajuda.component').then( m => m.TopicoAjudaComponent)
  },
  {
    path: 'dados-de-saude',
    loadComponent: () => import('./pages/dados-de-saude/dados-de-saude.page').then( m => m.DadosDeSaudePage)
  },
  {
    path: 'termos-e-condicoes',
    loadComponent: () => import('./pages/termos-e-condicoes/termos-e-condicoes.page').then( m => m.TermosECondicoesPage)
  },
  {
    path: 'meus-dados',
    loadComponent: () => import('./pages/meus-dados/meus-dados.page').then( m => m.MeusDadosPage)
  },
  {
    path: 'editar-senha',
    loadComponent: () => import('./pages/editar-senha/editar-senha.page').then( m => m.EditarSenhaPage)
  },
  {
    path: 'dependentes',
    loadComponent: () => import('./pages/dependentes/dependentes.page').then( m => m.DependentesPage)
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.page').then( m => m.LoginPage),
  },
  {
    path: 'primeiro-acesso',
    loadComponent: () => import('./pages/primeiro-acesso/primeiro-acesso.page').then( m => m.PrimeiroAcessoPage)
  },
  {
    path: 'esqueci-senha',
    loadComponent: () => import('./pages/esqueci-senha/esqueci-senha.page').then( m => m.EsqueciSenhaPage)
  },
  {
    path: 'logout',
    loadComponent: () => import('./pages/logout/logout.page').then( m => m.LogoutPage)
  },
  {
    path: 'termo-de-consentimento',
    loadComponent: () => import('./pages/termo-de-consentimento/termo-de-consentimento.page').then( m => m.TermoDeConsentimentoPage)
  },
  {
    path: 'politica-de-privacidade',
    loadComponent: () => import('./pages/politica-de-privacidade/politica-de-privacidade.page').then( m => m.PoliticaDePrivacidadePage)
  },
  {
    path: 'welcome',
    loadComponent: () => import('./pages/welcome/welcome.page').then( m => m.WelcomePage)
  },
  {
    path: 'sinaf-seguros',
    loadComponent: () => import('./pages/sinaf-seguros/sinaf-seguros.page').then(m => m.SinafSegurosPage)
  },
  {
    path: 'assistencia-funeral',
    loadComponent: () => import('./pages/assistencia-funeral/assistencia-funeral.page').then(m => m.AssistenciaFuneralPage)
  },
  {
    path: 'dasa',
    loadComponent: () => import('./pages/dasa/dasa.page').then(m => m.DasaPage)
  },
  {
    path: 'dasa/unidades-proximas',
    loadComponent: () => import('./pages/dasa/unidades-proximas/unidades-proximas.page').then(m => m.UnidadesProximasPage)
  },
  {
    path: 'dasa/exames',
    loadComponent: () => import('./pages/dasa/exames/exames.page').then(m => m.ExamesPage)
  },
  {
    path: 'farmacias',
    loadComponent: () => import('./pages/farmacias/farmacias.page').then( m => m.FarmaciasPage)
  },
  {
    path: 'farmacias/extrato',
    loadComponent: () => import('./pages/farmacias/extrato/extrato.page').then( m => m.ExtratoPage)
  },
  {
    path: 'farmacias/medicamentos',
    loadComponent: () => import('./pages/farmacias/medicamentos/medicamentos.page').then( m => m.MedicamentosPage)
  },
  {
    path: 'farmacias/minhas-receitas',
    loadComponent: () => import('./pages/farmacias/minhas-receitas/minhas-receitas.page').then( m => m.MinhasReceitasPage)
  },
  {
    path: 'farmacias/minhas-receitas/visualizar',
    loadComponent: () => import('./pages/farmacias/minhas-receitas/visualizar/visualizar.page').then( m => m.VisualizarPage)
  },
  {
    path: 'farmacias/unidades-proximas',
    loadComponent: () => import('./pages/farmacias/unidades-proximas/unidades-proximas.page').then(m => m.UnidadesProximasPage)
  },
  {
    path: 'farmacias/minhas-receitas/editar',
    loadComponent: () => import('./pages/farmacias/minhas-receitas/editar/editar.page').then( m => m.EditarPage)
  },
  {
    path: 'enviar-receita',
    loadComponent: () => import('./pages/enviar-receita/enviar-receita.page').then( m => m.EnviarReceitaPage)
  },
  {
    path: 'notifications',
    loadComponent: () => import('./pages/notifications/notifications.page').then( m => m.NotificationsPage)
  },


];
