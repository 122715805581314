import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface DialogInfo {
  title: string;
  content: string;
  buttons: Array<any>;
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
  ) { }

  dialogInfo: DialogInfo = {
    title: '',
    content: '',
    buttons: []
  };

  private buttonClickSubject = new Subject<string>();

  buttonClick$ = this.buttonClickSubject.asObservable();

  showModal: boolean = false;

  public get getDialogInfo(): DialogInfo {
    return this.dialogInfo;
  }

  public set setdialogInfo(dialogInfo: DialogInfo) {
    this.dialogInfo = dialogInfo;
  }

  public get getShowModal(): boolean {
    return this.showModal;
  }

  public set setShowModal(showModal: boolean) {
    this.showModal = showModal;
  }

  notifyButtonClick(buttonId: string) {
    this.buttonClickSubject.next(buttonId);
  }

}
