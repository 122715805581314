import { Directive, ElementRef, HostListener } from '@angular/core';
import { DirectiveService } from '../services/directive.services';

@Directive({
  selector: '[dateMask]',
  standalone: true
})
export class DateMaskDirective {

  keySelected: null | { backspacePressed: boolean;  deletePressed: boolean} = null;

  constructor(
    private el: ElementRef,
    private directiveSrv: DirectiveService
  ) { }

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    this.el = this.directiveSrv.handlerApplyMask(this.el, this.applyMask, this.keySelected);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: Event) {
    this.keySelected = this.directiveSrv.handlerOnKeyDown(event);
  }

  applyMask(value: string): string {
    value = value.replace(/\D/g, ''); // remove caracteres não numéricos
    if (value.length > 8) {
      // limita o tamanho da string em 8 caracteres
      value = value.slice(0, 8);
    }
    // adiciona as barras nos lugares corretos
    value = value.replace(/(\d{2})(\d)/, '$1/$2');
    value = value.replace(/(\d{2})(\d)/, '$1/$2');
    return value;
  }
}
