import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import {AlertService} from './alert.service';
import { NavigationService } from './navigation.service'
import { EventEmitter } from '@angular/core';

@Injectable()
export class BackButtonService {

  resetDataEvent: EventEmitter<void> = new EventEmitter<void>()
	constructor(
    private navigationService: NavigationService,
    private alertService: AlertService,
	) {
    this.setupBackButton();
	}

  private setupBackButton() {
    if (Capacitor.isNativePlatform()) {
      App.addListener('backButton', () => {
        if (Capacitor.getPlatform() === 'ios') {
          this.navigationService.previousUrl();
        } else {
          if(this.navigationService.currentUrlStr.includes('/primeiro-acesso')){
            this.showAlert();
          }else{
            this.navigationService.previousUrl();
          }
        }
      });
    }
  }

  showAlert(){
    let message ='Se você voltar, terá que prencher os dados desde o início. Deseja continuar?'
    this.alertService.showConfirmation(message).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.resetDataEvent.emit()
        this.navigationService.sendUrl('/login',{},true,true)
      }
    });
  }
}
