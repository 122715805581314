import { Injectable } from '@angular/core';
import { Location, ViewportScroller } from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart, NavigationExtras, Scroll } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { filter } from 'rxjs';

@Injectable()
export class NavigationService {

	public navParams:any = null;
	public previousUrlStr: string = "/";
	public currentUrlStr: string = "";

	private history: string[] = [];

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private location: Location,
    private viewportScroller: ViewportScroller,
	) {
		this.currentUrlStr = this.router.url;
		this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationStart) {

				//Atualiza os parâmetros da rota atual
				this.navParams = this.router.getCurrentNavigation()?.extras?.state || {}

			} else if (event instanceof NavigationEnd) {

        this.history.push(event.urlAfterRedirects);
				this.previousUrlStr = this.currentUrlStr;
				this.currentUrlStr = event.url;
			};

		});

	}

	/**
	 * Retorna os parâmetros passados para a rota
	 * @returns
	 */
	public getParams() {
    return this.router.getCurrentNavigation()?.extras?.state || {}
	}

	/**
	 * Retorna o histórico de rotas
	 * @returns
	 */
	public getHistory() {
		return this.history;
	}

	/**
	 * Limpa o histórico de rotas
	 * @returns
	 */
	public clearHistory() {
		this.history = [];
	}

	/**
	 * Retorna a rota atual
	 * @returns
	 */
	public getCurrentUrl() {
		return this.currentUrlStr;
	}

	/**
	 * Retorna a rota anterior
	 * @returns
	 */
	public getPreviousUrl() {
		return this.previousUrlStr;
	}

	/**
	 * Redireciona o usuário até a nova rota
	 * @param url
	 * @param params
	 */
	public sendUrl(url:string, params?:any, clearHistory:boolean = false, replaceUrl:boolean = false, fragment: string = '') {

    const navigationExtras: NavigationExtras = {
      state: params,
      replaceUrl: replaceUrl, // Defina como true se você quiser substituir a entrada do histórico pelo novo URL
    };

    if (fragment !== '' && fragment !== null && fragment !== undefined) {
      navigationExtras.fragment = fragment;
    }

		const navigationPromise = this.router.navigate([url], navigationExtras)

    navigationPromise.then(res => {
      if (!res) {
        console.error(`Não foi possível concluir o router navigate para a url [${url}]`);
      }

			if (clearHistory) {
				this.history = [];
			}
		});

    return navigationPromise;
	}

	/**
	 * Redireciona o usuário para a rota anterior
	 */
	public previousUrl(params?:any) {
		if (this.history.length > 0) {
      this.history.pop();
			this.location.back();
		} else {
      this.router.navigate(['home']);
    }
	}

  resolveAnchorScrolls(callback: Function) {
    this.router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: any) => {
      /*
      * O angular reconhece o fragment na URL porém tenta fazer o scroll antes do
      * componente estar pronto. Para resolver isso usamos um timeout e também o chamamos
      * duas vezes para que o scroll seja feita na posição correta.
      * Caso seja necessário implementar algo com o viewport para navigation
      * back e forward consultar:
      * https://quandh19.medium.com/how-to-enable-anchor-scrolling-of-angular-router-in-the-right-way-42e9b19657b5
      * https://stackblitz.com/edit/solution-anchor-scrolling?file=src%2Fapp%2Fapp.module.ts,src%2Fapp%2Fhome%2Fhome.component.ts
      *
      * Além disso, para que isso tudo funcione é crucial habilitar o anchorScroll do Angular no [main.ts] onde as rotas são habilitadas
      *
      // Opções de rolagem para âncoras
      const scrollingOptions: InMemoryScrollingOptions = {
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'disabled'
      };

      bootstrapApplication(AppComponent, {
        providers: [
          { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
          importProvidersFrom(IonicModule.forRoot({
            innerHTMLTemplatesEnabled: true
          })),
          // provideRouter(routes),
          provideRouter(routes, withInMemoryScrolling(scrollingOptions)),
        ],
      });
      *
      */
      if (e.anchor) {
        //setTimeout is the core line to conclude the solution
        setTimeout(() => {
          this.scrollToElement(callback);
        }, 500);
        setTimeout(() => {
          this.scrollToElement();
        }, 500);
      }
    });
  }

  scrollToElement(callback: Function | null = null) {
    this.route.fragment.subscribe(async f => {
      const element = document.querySelector("#" + f)
      if (element) {
        if (callback) callback();
        element.scrollIntoView({behavior: 'smooth', block: "start", inline: "nearest" });
      }
    });
  }
}
