// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: "https://devw-apicliente.vivasinaf.com.br",
  WEBURL: "https://hml-cliente.vivasinaf.com.br", // used to search and replace links in FAQ and Messages Notification
  DEEPLINK_URL_REPLACE: ".com.br",
  GoogleAuth: {
    clientId: '586522574728-342pug22h23m27gmau1do9q256i0h9bo.apps.googleusercontent.com',
    serverClientId: '586522574728-342pug22h23m27gmau1do9q256i0h9bo.apps.googleusercontent.com',
    androidClientId: "586522574728-342pug22h23m27gmau1do9q256i0h9bo.apps.googleusercontent.com",
    iosClientId: '586522574728-rgd7i467ei9n8lgb1e30e09qs2p5rhsg.apps.googleusercontent.com',
    scopes: ['profile', 'email'],
  },
  Facebook: {
    appId: '168048475890009',
    permissions: [
      'public_profile'
    ]
  },
  reCAPTCHKey: '6LfLYOQlAAAAAFfgjXzB38yj_y4komijjh3pU_6z',
  mockAPI: false,
  nextResend: 120,
  mapbox: {
    accesToken: 'pk.eyJ1Ijoic2luYWYtY2x1YmUtZGUtYmVuZWZpY2lvcyIsImEiOiJjbGRlam5hcnEwZTE3M3VvZHBxZXUwZnBuIn0.721p8uxdTJEL_6UZy5BoGQ'
  },
  localStorageKeys: {
    pendencias: "pendencias"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
