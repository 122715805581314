import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { InMemoryScrollingFeature, InMemoryScrollingOptions, RouteReuseStrategy, RouterFeatures, provideRouter, withInMemoryScrolling } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// Opções de rolagem para âncoras
const scrollingOptions: InMemoryScrollingOptions = {
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled'
};

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    importProvidersFrom(IonicModule.forRoot({
      innerHTMLTemplatesEnabled: true
    })),
    // provideRouter(routes),
    provideRouter(routes, withInMemoryScrolling(scrollingOptions)),
  ],
});
