import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private httpService: HttpService
  ) { }

  notReadNotifications: number = 0;

  /**
 * @description Obtém as notificações do usuário
 */
  async getNotifications(page:number, pageSize:number): Promise<any> {
    return await this.httpService.get(environment.API_URL + "/usernotification?page="+page+"&pageSize="+pageSize).toPromise();
  }

  /**
 * @description Salva a notificação como lida
 */
  async readNotification(notificationId: number): Promise<any> {
    return await this.httpService.put(environment.API_URL + "/usernotification/read/" + notificationId, null).toPromise();
  }

  /**
   * @description Obtém o número de notificações não lidas
   */
  public get getNotReadNotifications(): number {
    return this.notReadNotifications;
  }

  /**
   * @description Obtém o número de notificações não lidas
   */
  getNotReadCountNotifications() {
    this.httpService.get(environment.API_URL + "/usernotification/countNotRead").subscribe({
      next: res => {
        if (res && res > 0) {
          this.notReadNotifications = res;
        } else {
          this.notReadNotifications = 0;
        }
      },
      error: error => {
        console.error(error);
      }
    })
  }

  /**
   * @description Altera o número de notificações não lidas
   */
  public set setNotReadNotifications(notReadNotifications: number) {
    this.notReadNotifications = notReadNotifications;
  }

}
