import { CommonModule } from '@angular/common';
import { Component, NgZone, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { SplashScreen } from '@capacitor/splash-screen';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { IonicModule, Platform } from '@ionic/angular';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpModule } from './http.module';
import { AuthService } from './services/auth.service';
import { BackButtonService } from './services/back-button.service';
import { DependentesService } from './services/dependentes.service';
import { NavigationService } from './services/navigation.service';
import { NotificationsService } from './services/notifications.service';
import { SharedModule } from './shared.module';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { DialogService } from 'src/app/services/dialog.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, HttpModule, SharedModule, DialogComponent],
  providers: [AuthService, NavigationService, DependentesService, NotificationsService, BackButtonService, DialogService]
})
export class AppComponent implements OnInit {

  showTabs: boolean = true;

  static PendenciaEmmiter: Subject<number> = new Subject();
  private nextCallPendencia: string = moment().format();
  private inCallPendencia: boolean = false;

  constructor(
    public platform: Platform,
    private router: Router,
    public authService: AuthService,
    private dependenteService: DependentesService,
    private notificationsService: NotificationsService,
    private ngZone: NgZone,

    private backButtonService: BackButtonService,
    public dialogService: DialogService
  ) {
    this.nextCallPendencia = moment().format();
    this.router.events.subscribe(async (event) => {
      const pagesWithoutTabs = ['/login', '/primeiro-acesso', '/esqueci-senha', '/welcome'];
      if (!this.authService.isUserLogged() || pagesWithoutTabs.includes(this.router.url)) {
        this.showTabs = false;
      } else {
        this.showTabs = true;
      }

      this.callPendencia();
    });

    this.platform.ready().then(() => {
      this.inicializeSocialLoginPlugins();
      SplashScreen.hide();
      this.callPendencia();
    });
  }

  ngOnInit() {
    console.log('oninit appUrlOpen');
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      console.log('event appUrlOpen', event, environment.DEEPLINK_URL_REPLACE);
      this.ngZone.run(() => {
        const slug = event.url.split(environment.DEEPLINK_URL_REPLACE).pop();
        console.log('slug', slug);
        if(slug) {
          this.router.navigateByUrl(slug);
        }
      });
    }); 
  }

  callPendencia() {
    if (
      this.authService.isUserLogged() &&
      (new Date(this.nextCallPendencia) < new Date()) &&
      !this.inCallPendencia
    ) {
      this.inCallPendencia = true;
      this.getPendenteDependente();
      return true;
    }
    return false;
  }

  getPendenteDependente() {
    this.dependenteService.contaQtdPendencias().subscribe(res => {
      this.nextCallPendencia = moment().add(2, 'minute').format();
      localStorage.setItem(environment.localStorageKeys.pendencias, String(res));
      AppComponent.PendenciaEmmiter.next(res);
      this.inCallPendencia = false;
    }, error => {
      this.inCallPendencia = false;
      console.error('getPendenteDependente', error);
    });
  }

  async checkToken() {
    const token = await this.authService.getAccessToken();
    if (!token) this.authService.logout();
  }

  inicializeSocialLoginPlugins() {
    if (!this.platform.is('capacitor')) {
      GoogleAuth.initialize({
        clientId: environment.GoogleAuth.clientId,
        scopes: environment.GoogleAuth.scopes,
        grantOfflineAccess: true,
      });
    }

    FacebookLogin.initialize({ appId: environment.Facebook.appId });
  }

  onButtonClick(button: any) {
    this.dialogService.notifyButtonClick(button);
  }
}
