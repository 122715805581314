import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { AlertService } from './services/alert.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  refreshTokenInProgress = false;
  refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService, private alertService: AlertService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.endsWith('/user/login')) {
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error?.statusText == "Unknown Error" || error?.error?.statusCode == 500) {
            this.alertService.showStandardError();
            return throwError({...error, errorDisplayed: true});
          } else {
            return throwError(error);
          }
        })
      );
    }

    const allowedEndpoints: string[] = [
      'subscriber/redefinePassword',
      'subscriber/sendRecoveryToken',
      'subscriber/verifyToken',
      'subscriber/validateToken/dependent',
      'subscriber/socialLogIn',
    ];

    for (const endpoint of allowedEndpoints) {
      if (request.url.endsWith(endpoint)) {
        return next.handle(request);
      }
    }

    return this.addAuthenticationToken(request).pipe(
      switchMap(requestWithToken => {
        return next.handle(requestWithToken).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error?.error?.statusCode == 500 || error?.status == 500 || error?.status == 502) {
              if(!request.url.includes('usernotification')){
              this.alertService.showStandardError();
              return throwError({...error, errorDisplayed: true});
              }else{
                return throwError(error);
              }
            }

            if (error.status !== 401) {
              if (error?.statusText == "Unknown Error") {
                this.alertService.showStandardError();
                return throwError({...error, errorDisplayed: true});
              } else {
                return throwError(error);
              }
            }

            if (this.refreshTokenInProgress && requestWithToken.url.endsWith('/subscriber/refreshToken')) {
              // falha ao tentar fazer o refreshToken, encerra e vai pra login
              this.authService.logout();
              return throwError(error);
            }

            if (this.refreshTokenInProgress) {
              console.error('refresh token in progress:', error);

              return this.refreshTokenSubject.pipe(
                filter((result: any) => result !== null),
                take(1),
                switchMap(() => {
                  return next.handle(requestWithToken);
                })
              );
            } else {
              this.refreshTokenInProgress = true;
              this.refreshTokenSubject.next(null);

              return this.authService.refreshToken().pipe(
                switchMap((refresh_response: any) => {
                  this.authService.setUserSession(refresh_response);

                  this.refreshTokenInProgress = false;
                  this.refreshTokenSubject.next('teste');
                  return this.addAuthenticationToken(requestWithToken).pipe(
                    switchMap(requestWithNewToken => {
                      return next.handle(requestWithNewToken);
                    })
                  )
                }),
                catchError(err => {
                  this.refreshTokenInProgress = false;
                  return throwError(err);
                })
              );
            }
          })
        );
      })
    );
  }

  addAuthenticationToken(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    return from(this.authService.getAccessToken()).pipe(
      map(token => {
        if (!token) this.authService.logout();
        return request.clone({ setHeaders: { 'Authorization': 'Bearer ' + token } })
      }),
      catchError(error => {
        console.error('Erro addAuthenticationToken: ', error);
        return of(request); // Retorna a requisição original em caso de erro
      })
    );
  }
}
