export enum InvitationStatus {
  INVITATION_NOT_SENT = 'INVITATION_NOT_SENT',
  INVITATION_SENT = 'INVITATION_SENT',
  INCOMPLETE_REGISTRATION = 'INCOMPLETE_REGISTRATION',
  INVITATION_ERROR = 'INVITATION_ERROR',
  REGISTRATION_COMPLETED = 'REGISTRATION_COMPLETED',
  READY_FOR_FIRST_ACCESS = 'READY_FOR_FIRST_ACCESS',
}

export const InvitationStatusDescriptions: {[key in InvitationStatus]: string} = {
  [InvitationStatus.INVITATION_NOT_SENT]: 'Convite pendente',
  [InvitationStatus.INVITATION_SENT]: 'Convite enviado aguardando primeiro acesso',
  [InvitationStatus.INCOMPLETE_REGISTRATION]: 'Convite pendente',
  [InvitationStatus.INVITATION_ERROR]: 'Erro no envio do convite',
  [InvitationStatus.REGISTRATION_COMPLETED]: 'Primeiro acesso realizado',
  [InvitationStatus.READY_FOR_FIRST_ACCESS]: 'Convite pendente',
};

export const InvitationStatusIcons: {[key in InvitationStatus]: string} = {
  [InvitationStatus.INVITATION_NOT_SENT]: 'carteirinha',
  [InvitationStatus.INVITATION_SENT]: 'mark_email_read',
  [InvitationStatus.INCOMPLETE_REGISTRATION]: 'carteirinha',
  [InvitationStatus.INVITATION_ERROR]: 'sms_failed',
  [InvitationStatus.REGISTRATION_COMPLETED]: 'task_alt',
  [InvitationStatus.READY_FOR_FIRST_ACCESS]: 'carteirinha',
};

export const InvitationStatusIconsBackground: {[key in InvitationStatus]: string} = {
  [InvitationStatus.INVITATION_NOT_SENT]: 'bg-danger',
  [InvitationStatus.INVITATION_SENT]: 'bg-warning',
  [InvitationStatus.INCOMPLETE_REGISTRATION]: 'bg-danger',
  [InvitationStatus.INVITATION_ERROR]: 'bg-danger',
  [InvitationStatus.REGISTRATION_COMPLETED]: 'bg-success',
  [InvitationStatus.READY_FOR_FIRST_ACCESS]: 'bg-danger',
};
