import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient
  ) {
  }

  get(url: string, headers : any = {}): Observable<any> {
    let httpHeaders = new HttpHeaders(headers);
    return this.http.get(url, { headers : httpHeaders});
  }

  post(url: string, data: any, headers: any = {}): Observable<any> {
    let httpHeaders = new HttpHeaders(headers);
    return this.http.post(url, data, { headers : httpHeaders });
  }

  put(url: string, data: any, headers : any = {}): Observable<any> {
    let httpHeaders = new HttpHeaders(headers);
    return this.http.put(url, data, { headers : httpHeaders });
  }

  patch(url: string, data: any, headers : any = {}): Observable<any> {
    let httpHeaders = new HttpHeaders(headers);
    return this.http.patch(url, data, { headers : httpHeaders });
  }

  delete(url: string, headers : any = {}): Observable<any> {
    let httpHeaders = new HttpHeaders(headers);
    return this.http.delete(url, { headers : httpHeaders });
  }
}
