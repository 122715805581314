<ion-app>
  <ion-router-outlet></ion-router-outlet>

  <ion-tabs>
    <ion-tab-bar slot="bottom" *ngIf="showTabs">
      <ion-tab-button tab="home">
        <i class="sinaf-icons tab-bar home"></i>
        Início
      </ion-tab-button>
      <ion-tab-button tab="carteirinha">
        <i class="sinaf-icons tab-bar carteirinha"></i>
        Carteirinha
      </ion-tab-button>
      <!-- <ion-tab-button tab="dados-de-saude">
        <i class="sinaf-icons tab-bar cardiologia"></i>
        Dados de Saúde
      </ion-tab-button> -->
      <ion-tab-button tab="ajuda">
        <i class="sinaf-icons tab-bar ajuda"></i>
        Ajuda
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</ion-app>

<app-dialog  >
  <div class="content" [innerHTML]="dialogService.getDialogInfo.content">
  </div>

  <ng-container actions>
    <div class="dialog-btns" *ngFor="let button of dialogService.getDialogInfo.buttons">
      <button class="btn btn-primary ion-padding" type="submit" expand="block" (click)="onButtonClick(button)">{{button.text}}</button>
    </div>
  </ng-container>
</app-dialog>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
</style>
